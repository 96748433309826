.bkz-post-like.loading {
	.icon-like, .icon-unlike {
		animation: bkz_pulse 1s linear infinite;
	}
}

@keyframes bkz_pulse {
	0% {
		transform: scale(1.1);
	}
	50% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}