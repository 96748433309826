.rounded {
  border-radius: 10px;
}

.rounded-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

body {
  font-weight: normal;
}

.accent {
  background-color: $brand-primary;
  color: #fff;
}

.navbar-text{
  white-space: nowrap;
}

main p,.texter li{
  font-size:18px;
  line-height:32px;
}
.texter .vc_col-sm-12 .wpb_text_column{
  max-width:860px;
  margin:0 auto;
}

.texter ul{
  column-count:2
}

/*
#tinymce {
  color: #000000;
}*/

a {
  //border-bottom: 1px #fff solid;

  &.brand {
    border: none;

    &:hover{
      border: none;
    }
  }

  &:hover {
    //border-bottom: 1px $link-hover-color solid;
  }
}

h5.top,h5.bottom{color:#fff!important;line-height:32px!important}

.hero {
  height: auto;
  padding-bottom: 1.5em;
  &.hero-main{
    height: 650px !important;
    padding-bottom: 0 !important;

    h1 {
      background-color: $brand-primary !important;
    }
  }
  h1 {
    font-weight: 400;
    background-color: #fc9a28;
    line-height: 60px;
    padding: 0 10px;
    display: inline-block;
    margin: 5px 0 0 0;
    color: #fff;
    max-width: 650px;
    float: left;
    clear: both;
  }
  h5 {
    font-weight: normal;
  }
}

.quote-text {
  font-style: italic;
  padding-left: 10px;
  h6 {
    line-height: 27px;
  }
  &:before{
    content: '"';
    font-family: 'Georgia';
    float: left!important;
    display: inline;
    font: italic 50px Georgia;
    color: #d7d8d7;
    line-height: 50px;
    width: 15px;
    position: absolute;
    left: -3px;
  }
}

.member-photo {
  float: left;
  img {
    border-radius: 60%;
    width: 120%;
    max-width: 50px;
    height: auto;
    border: 1px solid #ccc;
    margin: 0 15px 0 0!important;
  }
}
.member-text {
  color: #777;
  float: left;
  .name {
    line-height: 30px;
    font-weight: 700;
  }
}

.member-name {
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.company-name {
  p {
    line-height: 21.4285717010498px;
    font-size: 15px;
  }
}


.logos {
  img {
    opacity: 0.6;
    max-width: 105px;
  }
}

.shadowed-text {
  text-shadow: 0 2px 2px rgba(0,0,0,.4);
}

/*div.vc_custom_1438891735759 {
  background-size: initial !important;
  background-position: top left  !important;
}*/

h4 {
  font-weight: 300;
}

.content-box a{
  color: #08628c;
  text-decoration: underline;
  border-bottom: none;
}

.btn-outline {
  background-color: transparent;
  color: #fff;
  transition: all .5s;
}

article {
  padding-bottom: 30px;
}

footer.content-info {
  clear: both;
  color: rgba(255, 255, 255, 0.75);
  font-weight: normal;
  font-size: 16px;
  float: left;
  width: 100%;
  padding: 70px 0;

  .footer-widgets {
    margin: 0 auto;
    float: none;
    padding: 0 20px;
    position: relative;

    ul {
      list-style: none;
      margin: 0 0 30px 0;
      padding-left: 0;
      li {
        padding: 5px 0;
      }
    }

    .widget {
      width: 100%;
      float: left;
      margin: 0 auto 1.5em;

      @media screen and (max-width: 600px) {
          clear: both;
          max-width: 100% !important;
        }

      &.widget_text {
        max-width: 45%;
        padding-right: 5%;

        h3 {
          margin: 0 0 15px 0;
          text-indent: -30000px;
          background: url(../../../../uploads/l.png) no-repeat 0 0;
          height: 60px;
          width: 100%;
          background-size: 50%;
        }
      }

      &.widget_nav_menu {
        max-width: 23%;
        padding-right: 2%;

        h3 {
          color: #fff;
          margin-top: 0;
          text-transform: uppercase;
          font-weight: normal;
          font-size: 15px;
        }

        a {
          color: rgba(255, 255, 255, 0.75);
        }
      }
    }
  }
}

/*
.banner {
  //position: absolute;
  padding: 20px 60px 0px 60px;
  width: 100%;
  z-index: 1;

  a {
    color: #fff;
  }

  .nav {
    padding-top: 5px;
    display: table;
    width: 100%;
  }
  .nav>li {
    display: table-cell;
    padding-right: 4%;
    max-width: 200px;
    &:last-child {
      padding: 0;
    }
  }
  .nav>li>a {
    padding: 0;
    display: inline;
    border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
    &:hover, &:focus {
      background: none;
      border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
    }
  }
}
*/

.banner {
  a {
    color: #fff;

    &:focus, &:hover {
      background-color: transparent !important;
    }
  }

  .navbar-toggle .icon-bar {
    background-color: #fff;
  }



  .row {
    margin: 0 0 5px 0;
  }

  .widget {
    margin-top: 7px;
    margin-left: 10px;
  }

  @media screen and (max-width: 768px) {
    .row {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  input {
    @include placeholder {
      color: #fff !important;
    }
  }
}

.scrolled {
  position: fixed;
  background-color: rgba(51,51,51,0.8);
}

.special {
  background-color: #fc9a28;
  border-radius: 50px;
}

.contact {
  input[type="submit"]{
    color: #ffffff;
    background-color: #ff9900;
    border-radius: 2em;
    padding: 18px 25px;
    border: 1px solid transparent;
  }
}

header.banner {
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  transition: all .25s ease-in-out;

  &.slide--down {
    transform: translateY(-100%);
  }
  &.slide--up {
    transform: translateY(0);
  }
}

body {
  margin-top: 154px;
}

// Gravity forms fix
ul.gform_fields, ul.gfield_checkbox, ul.gfield_radio{
  margin-top: 1em;
  padding: 0;
}